import React from 'react'
import Hero from "../components/Hero";
import RecentProjects from "../components/RecentProjects";

const Home = () => {
  return (
    <div>
        <Hero />
        <RecentProjects />
    </div>
  )
}

export default Home